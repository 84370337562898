import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import config from '../../../../../../client/config.client'
import axios from 'axios'
import { Box, Image, InputGroup, Input, Heading, Spinner } from '@chakra-ui/react'
import { CheckCircleRounded } from '../../../atoms/Icon'

const gifContainerStyles = {
	'w': 'full',
	'& picture:hover': {
		cursor: 'pointer',
	},
}

const gifOverlayStyles = {
	pos: 'absolute',
	left: 0,
	top: 0,
	right: 0,
	bottom: '10px',
	background: 'rgba(0, 0, 0, 0.4)',
	display: 'flex',
	color: 'white',
	justifyContent: 'center',
	alignItems: 'center',
	borderRadius: 'md',
}

const GifSearch = ({ onGifClick, defaultSearch, limit, selectedUrls }) => {
	const [loading, setLoading] = useState(true)
	const [gifs, setGifs] = useState([])
	const [offset, setOffset] = useState(0)
	const [search, setSearch] = useState(defaultSearch)
	const [query, setQuery] = useState(defaultSearch)
	const gifGridRef = useRef()

	// configure your fetch: fetch 15 gifs at a time as the user scrolls (offset is handled by the 'next' key in the response)
	const fetchGifs = async () => {
		let url = `https://tenor.googleapis.com/v2/search?q=${query}&limit=15&pos=${offset}&contentfilter=high&media_filter=loopedmp4,tinygif&key=${config.gapi.tenor}&client_key=${config.gapi.clientID}`
		let response = await axios.get(url)
		if (response && response.data.next != offset) {
			setOffset(response.data.next) // Only update offset if it's not null/undefined
			setGifs((prevGifs) => [...prevGifs, ...response.data.results])
			setLoading(false)
		} else {
			setLoading(false)
		}
	}

	console.log('gifs', gifs)
	// Timeout for throttling
	let timeoutId = null

	const handleScroll = () => {
		// Throttle the fetchGifs call
		if (timeoutId) return
		timeoutId = setTimeout(() => {
			const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
			const windowHeight = window.innerHeight
			const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight
			// Check if the user is near the bottom of the page (within 100px)
			if (scrollHeight - scrollTop - windowHeight < 50 && !loading) {
				fetchGifs() // Trigger fetching new GIFs
				setLoading(true)
			}
			// Clear the timeout after execution
			timeoutId = null
		}, 300) // Adjust the debounce time (300ms is an example)
	}

	useEffect(() => {
		window.addEventListener('scroll', handleScroll)
		return () => {
			window.removeEventListener('scroll', handleScroll)
			if (timeoutId) clearTimeout(timeoutId)
		}
	}, [fetchGifs])

	useEffect(() => {
		var term = query != '' ? query : defaultSearch
		setOffset(0)
		setGifs([])
		const typeTimer = setTimeout(() => {
			setSearch(term)
			if (query != '') fetchGifs()
		}, 1200)
		return () => clearTimeout(typeTimer)
	}, [query])

	// Overlay component gets GifOverlayProps
	const Overlay = () => {
		return (
			<Box sx={gifOverlayStyles}>
				<CheckCircleRounded fontSize="large" />
			</Box>
		)
	}

	return (
		<Box sx={gifContainerStyles} ref={gifGridRef}>
			<InputGroup mb="1rem">
				<Input
					size="lg"
					placeholder="Search for Gif via Tenor"
					name="search"
					onChange={(e) => setQuery(e.target.value)}
					defaultValue={search}
				/>
			</InputGroup>

			<Box
				key={offset}
				padding={4}
				w="100%"
				maxW="900px"
				mx="auto"
				sx={{ columnCount: [1, 2, 3], columnGap: '8px' }}>
				{gifs.length > 0 &&
					gifs.map((gif, i) => {
						if (selectedUrls.includes(gif.media_formats.loopedmp4.url)) {
							return (
								<Box
									key={i}
									onClick={() => onGifClick(gif.media_formats.loopedmp4.url)}
									overflow="hidden"
									position="relative">
									<Image
										w="100%"
										borderRadius="md"
										mb={1}
										d="inline-block"
										src={gif.media_formats.tinygif.url}
										alt={gif.title}
									/>
									<Overlay />
								</Box>
							)
						} else {
							return (
								<Box key={i} onClick={() => onGifClick(gif.media_formats.loopedmp4.url)}>
									<Image
										w="100%"
										borderRadius="sm"
										mb={1}
										d="inline-block"
										src={gif.media_formats.tinygif.url}
										alt={gif.title}
									/>
								</Box>
							)
						}
					})}
			</Box>
			{loading && <Spinner color="gray.500" thickness="6px" speed="0.65s" emptyColor="gray.100" size="xl" />}
		</Box>
	)
}

/* **************************************
DEFAULT PROPS VALUE
************************************** */

GifSearch.defaultProps = {
	limit: 16,
	onGifClick: null,
	defaultSearch: 'celebrate',
	selectedUrls: [],
}

/* **************************************
COMPONENT PROPS TYPES
************************************** */

GifSearch.propTypes = {
	limit: PropTypes.number,
	onGifClick: PropTypes.func.isRequired,
	defaultSearch: PropTypes.string,
	selectedUrls: PropTypes.array,
}
export default GifSearch
